<template>
  <div class="body-content">
    <div class="container content-section" style="min-height: 350px;">
      <div class="col-8 text-left offset-2 position-relative">
        <img src="../assets/images/banners/for-partners-banner.jpg" alt="For Partners" class="display-image overlayed-image position-absolute" />

        <h1 class="h1 main-title title-overlay d-inline-block position-relative" style="padding-top:20px;">For Partners</h1>
        <p class="position-relative header-paragraph" style="max-width: 400px;">Resources and information for exisitng and future partners.</p>
<!--        <div class="header-button">-->
<!--          <a class="btn btn-primary position-relative" href="">Media Kit</a>-->
<!--        </div>-->
      </div>
      <div class="col-2">
      </div>
    </div>

    <div class="container-fluid content-section">
      <div class="col-8-md offset-2-md">
        <h2 class="h2 paragraph-title text-center d-inline-block position-relative">explor is...</h2>

        <p class="text-center main-paragraph">
          a website where high school and undergraduate students can learn about various healthcare careers. Healthcare has a lot of different opportunities that students may or may not be familiar with. People know about doctors, nurses, and pharmacists, but there are many more careers that are vital to the healthcare system.        </p>
      </div>
    </div>

    <div class="container-fluid content-section">
      <div class="col-8-md offset-2-md">
        <h2 class="h2 paragraph-title text-center d-inline-block position-relative">explor serves...</h2>

        <p class="text-center main-paragraph">
          to connect students directly with various university healthcare programs to learn more about different career paths available to them. These programs offer content that covers multiple practice settings, what strengths are best suited for these roles, student and alumni interviews, and more.
        </p>
      </div>
    </div>

    <div class="parallax content-section" style="min-height: auto; padding-bottom: 50px;">
      <div class="container position-relative" style="z-index: 10; order: 2">
        <h1 class="h1 main-title light-title title-overlay title-overlay-small-margin text-center d-inline-block position-relative" style="padding-top:20px; width: 100%">Interested?</h1>
        <div style="margin-top: 25px;">
          <p style="max-width: 500px; margin: 0 auto;">
            If you are a social media personality or influencer with an audience that would be interested in pharmacy school we may be interested in a partnership deal. Fill out the form below to apply for consideration, and we will be in touch soon.
          </p>
          <div style="max-width: 500px; margin: 20px auto; color: #fff; text-align: center">

            <a class="btn btn-primary" href="mailto:info@corereadiness.com" style="width: 300px; margin: 0 auto" >Contact Us</a>
<!--            <form style="z-index: 10">-->
<!--              <div class="form-group">-->
<!--                <label for="full_name">Full Name</label>-->
<!--                <input type="text" class="form-control" id="full_name" aria-describedby="emailHelp" placeholder="">-->
<!--                <small id="emailHelp" class="form-text text-muted">We'll never share your information with anyone else.</small>-->
<!--              </div>-->

<!--              <div class="form-group" style="margin-top: 15px;">-->
<!--                <label for="tiktok">TikTok</label>-->
<!--                <input type="text" class="form-control" id="tiktok" placeholder="">-->
<!--              </div>-->

<!--              <div class="form-group" style="margin-top: 15px;">-->
<!--                <label for="twitter">Twitter</label>-->
<!--                <input type="text" class="form-control" id="twitter" placeholder="">-->
<!--              </div>-->

<!--              <div class="form-group" style="margin-top: 15px;">-->
<!--                <label for="instagram">Instagram</label>-->
<!--                <input type="text" class="form-control" id="instagram" placeholder="">-->
<!--              </div>-->

<!--              <div class="form-group text-center" style="margin-top: 25px;">-->
<!--                <button class="btn btn-primary" type="submit" style="width: 300px; margin: 0 auto" >Submit</button>-->
<!--              </div>-->
<!--            </form>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>