import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Faq from "../views/Faq.vue";
import ForPartners from "../views/ForPartners.vue";
// import News from "../views/News.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'explor Pharmacy',
            metaTags: [
                {
                    name: 'description',
                    content: 'Discover the diverse career paths a pharmacy degree can lead to!'
                }
            ]
        }
    },
    {
        path: "/faq",
        name: "Frequently Asked Questions",
        component: Faq,
        meta: {
            title: 'Frequently Asked Questions',
            metaTags: [
                {
                    name: 'description',
                    content: 'Get answers to some common pharmacy school questions.'
                }
            ]
        }
    },
    {
        path: "/about",
        name: "About",
        component: About,
        meta: {
            title: 'About explor',
            metaTags: [
                {
                    name: 'description',
                    content: 'Learn about explor\'s mission, values, and desire to help students find their place in pharmacy.'
                }
            ]
        }
    },
    {
        path: "/for-partners",
        name: "For Partners",
        component: ForPartners,
        meta: {
            title: 'For Partners',
            metaTags: [
                {
                    name: 'description',
                    content: 'Resources and information for existing and future partners.'
                }
            ]
        }
    }
    // {
    //     path: "/news",
    //     name: "News",
    //     component: News,
    //     meta: {
    //         title: 'Follow Us',
    //         metaTags: [
    //             {
    //                 name: 'description',
    //                 content: 'Discover the diverse career paths a pharmacy degree can lead to!'
    //             }
    //         ]
    //     }
    // },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: function(to, from, savedPosition) {
        if (to.hash) {
            return {el: to.hash}
        } else if (savedPosition) {
            return savedPosition;
        } else {
            window.scrollTo(0, 0)
            return { x: 0, y: 0 }
        }
    },
});

export default router;