<template>
  <div class="container" style="margin-top: 50px; padding-bottom: 35px;">
    <footer class="pt-4 pt-md-5">
      <div class="row">
        <div class="col-12 col-md">
          <router-link to="/" class="text-muted" href="#">
            <img class="mb-2" src="../assets/images/primary-logo.svg" alt="" style="max-width: 150px;">
          </router-link>
          <small class="d-block mb-3 text-muted">© {{ current_year }} CORE Higher Education Group</small>
        </div>
        <div class="col-6 col-md">
          <h5>explor</h5>
          <ul class="list-unstyled text-small">
            <li><router-link to="/" class="text-muted" href="#">Home</router-link></li>
            <li><router-link to="/about" class="text-muted" href="#">About Us</router-link></li>
<!--            <li><router-link to="/news" class="text-muted" href="#">News</router-link></li>-->
            <li><a href="https://corehighered.com/" target="_blank" class="text-muted">CORE Higher Ed Website</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Resources</h5>
          <ul class="list-unstyled text-small">
            <li><router-link to="/faq" class="text-muted" href="#">FAQs</router-link></li>
            <li><a href="https://www.instagram.com/explor.pharmacy/" target="_blank" class="text-muted">Instagram</a></li>
            <li><a href="https://www.tiktok.com/@explor.pharmacy?lang=en" target="_blank" class="text-muted">Tiktok</a></li>
            <li><a href="https://twitter.com/explorpharmacy" target="_blank" class="text-muted">Twitter</a></li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
// import $ from 'jquery'

export default {
  name: 'MainFooter',
  props: {
  },
  data() {
    return {
      current_year: ''
    }
  },
  created() {
    this.current_year = new Date().getFullYear()
  }
}
</script>

<style scoped>

</style>
