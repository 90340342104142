import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import '@fortawesome/fontawesome-free'
import JQuery from 'jquery'
import mitt from 'mitt'
import { VueMasonryPlugin } from "vue-masonry/src/masonry-vue3.plugin";

const emitter = mitt()
window.$ = JQuery

let app = createApp(App)
app.component('main-navigation', require('./components/MainNavigation.vue').default)
app.component('main-footer', require('./components/MainFooter.vue').default)
app.component('map-widget', require('./components/MapWidget.vue').default)
app.config.globalProperties.emitter = emitter
app.use(VueMasonryPlugin)
app.use(router)
app.mount('#app')
