<template>
  <div class="col-12 text-center position-relative" style="z-index: 1;">
    <router-link to="/" class="">
      <img alt="Explor Logo" class="light-primary-logo" style="max-width: 200px; z-index: 1;" src="../assets/images/primary-logo-white.svg">
      <img alt="Explor Logo" class="dark-primary-logo" style="max-width: 200px; z-index: 1;" src="../assets/images/primary-logo.svg">
    </router-link>
  </div>

  <nav class="navbar navbar-expand-lg sticky-top navbar-light">
    <div class="container">
      <div class="w-25 order-1 order-md-0">
        <ul class="navbar-nav mr-auto navbar-social-list">
          <li class="nav-item icon-hidden-default">
            <router-link to="/" class="nav-link">
              <img alt="Explor Icon" src="../assets/images/primary-logo-icon.svg">
            </router-link>
          </li>
          <li class="nav-item align-items-center social-icons d-flex">
            <a href="https://www.instagram.com/explor.pharmacy/" target="_blank"><i class="fab fa-2x fa-instagram" style="height:fit-content"></i></a>
          </li>
          <li class="nav-item align-items-center social-icons d-flex">
            <a href="https://www.tiktok.com/@explor.pharmacy?lang=en" target="_blank"><i class="fab fa-2x fa-tiktok" style="height:fit-content"></i></a>
          </li>
          <li class="nav-item align-items-center social-icons d-flex">
            <a href="https://twitter.com/explorpharmacy" target="_blank"><i class="fab fa-2x fa-twitter" style="height:fit-content"></i></a>
          </li>
        </ul>
      </div>

      <button id="navbarSupportedContent" class="navbar-toggler order-2" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse-target" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="mx-auto w-50 justify-content-center order-3 navbar-collapse collapse navbar-collapse-target">
        <ul class="navbar-nav main-nav-links mr-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link main-link">Home</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link to="/#explor-pharmacy" class="nav-link">explor Pharmacy</router-link>-->
<!--          </li>-->
          <li class="nav-item">
            <router-link to="/faq" class="nav-link main-link">Frequently Asked Questions</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link main-link">About Us</router-link>
          </li>
<!--          <li class="nav-item">-->
<!--            <router-link to="/news" class="nav-link main-link">News</router-link>-->
<!--          </li>-->
        </ul>
      </div>
      <div class="navbar-collapse collapse w-25 order-4 dual-collapse2 navbar-collapse-target navbar-side-button justify-content-end">
      </div>
    </div>
  </nav>


</template>

<script>
import $ from 'jquery'

export default {
  name: 'MainNavigation',
  props: [],
  mounted() {
    $(function () {
      $(document).scroll(function () {
        let $nav = $(".navbar.sticky-top");
        if ($('.navbar-collapse').hasClass('show') === false) {
          $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height() + 30);
        }
      });
    });
  }
}

setTimeout(
    function () {
      let myCollapsible = document.querySelector('.navbar-collapse-target')
      if (myCollapsible != null) {
        myCollapsible.addEventListener('show.bs.collapse', function () {
          let $nav = $(".navbar.sticky-top");
          $nav.addClass('scrolled');
        })

        myCollapsible.addEventListener('hide.bs.collapse', function () {
          let $nav = $(".navbar.sticky-top");
          if ($(document).scrollTop() > $nav.height() + 30 === false) {
            $nav.removeClass('scrolled');
          }
        })
      }
    }, 200
);

</script>

<style scoped>
.navbar.scrolled {
  background: #fff;
}
.no-focus-btn:focus {
  box-shadow: none !important;
}
.main-link.router-link-active {
  color: rgba(0, 0, 0, 0.9);
  font-weight: 600;
}
.scrolled.navbar-light .navbar-nav .nav-link:hover,
.scrolled.navbar-light .navbar-nav .nav-link:focus {
  color: #fff;
}
.social-icons a {
  color: #4f4f4f;
}
.explor-Pharmacy .navbar-light .navbar-nav .nav-link,
.explor-Pharmacy .social-icons a {
  color: #fff;
  text-shadow: 0 3px 4px rgb(0 0 0 / 35%);
}

.scrolled.navbar-light .navbar-nav .nav-link,
.scrolled .social-icons a {
  color: #4f4f4f;
  text-shadow:none;
}
.scrolled.navbar-light .navbar-nav .nav-link:hover,
.scrolled.navbar-light .navbar-nav .nav-link:focus {
  color: #4f4f4f;
}
.light-primary-logo {
  display: none;
}
.dark-primary-logo {
  display: inherit;
}
.explor-Pharmacy .dark-primary-logo {
  display: none;
}
.explor-Pharmacy .light-primary-logo {
  display: inherit;
}
@media (max-width: 991.98px) {
  .navbar-nav.main-nav-links {
    /*background: #fff;*/
    /*border-radius: 6px;*/
    /*box-shadow: 0 11px 13px rgb(0 0 0 / 7%);*/
    /*margin: 0 auto;*/
    /*color: #4f4f4f !important;*/
  }
  .scrolled .navbar-nav.main-nav-links {
    /*background: transparent;*/
    /*border-radius: 0;*/
    /*box-shadow: none;*/
    /*margin: 0 auto;*/
  }
}
</style>
