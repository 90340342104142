<template>
  <div class="body-content">
    <section class="hero position-relative">
      <div class="video-hero-container">
        <div class="video-tinted"></div>
        <video autoplay muted loop id="hero-video-bg" class="d-sm-block" style="z-index: -1;" poster="../assets/videos/Recruit_Video_Banner_Poster.png" v-if="!isMobile()">
          <source src="../assets/videos/Recruit_Video_Banner_Sm.mp4" type="video/mp4">
        </video>
        <div class="video-default-image d-md-none" v-if="!isMobile()"></div>

        <div class="video-default-image" v-if="isMobile()"></div>
      </div>

      <div class="hero-body">
        <div class="row">
          <div class="col-8 text-left offset-2 position-relative">
<!--            <img src="../assets/images/banners/home-banner.jpg" alt="Explor" class="display-image overlayed-image position-absolute" />-->

            <h1 class="h1 main-title video-title-overlay light-main-title title-overlay d-inline-block position-relative" style="padding-top:20px;">Where Can a Pharmacy<br/>Degree Take Me?</h1>
            <p class="position-relative header-paragraph" style="max-width: 400px; color: #fff">Visit the Pharmacy EXPLORATION CENTERS of these leading pharmacy schools to discover the diverse career paths and opportunities.</p>
          </div>
          <div class="col-2">
          </div>
        </div>
      </div>
    </section>
  </div>
  <map-widget></map-widget>

  <div class="parallax content-section" style="min-height: auto; padding-bottom: 40px;margin-top: 45px;">
    <div class="container">
      <div class="row col-10 offset-1" style="padding-top:50px; ">
        <div class="col-md-4 ">
          <h4 class="h4">Learn</h4>
          <p>
            More about pharmacy and various career paths including community, clinical, industry, business, compounding and more!
          </p>
        </div>
        <div class="col-md-4">
          <h4 class="h4">Experience</h4>
          <p>
            Engaging content provided by colleges of pharmacy and what their programs have to offer.
          </p>
        </div>
        <div class="col-md-4">
          <h4 class="h4">Connect</h4>
          <p>
            Directly with admissions and stand out among applicants.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MapWidget from "../components/MapWidget";

export default {
  components: {
    MapWidget
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>

<style>

</style>