<template>
  <div class="body-content">
    <section class="hero position-relative">
      <div class="video-hero-container">
        <div class="video-tinted"></div>
        <video autoplay muted loop id="hero-video-bg" class="d-sm-block" style="z-index: -1;" poster="../assets/videos/Recruit_Video_Banner_Poster.png" v-if="!isMobile()">
          <source src="../assets/videos/Recruit_Video_Banner_Sm.mp4" type="video/mp4">
        </video>
        <div class="video-default-image d-md-none" v-if="!isMobile()"></div>

        <div class="video-default-image" v-if="isMobile()"></div>
      </div>

      <div class="hero-body">
        <div class="row" style="margin-top: 75px;">
          <div class="col-8 text-left offset-2 position-relative">

            <h1 class="h1 main-title video-title-overlay light-main-title title-overlay d-inline-block position-relative" style="padding-top:20px;">Pharmacy FAQ</h1>
            <div class="header-button">
              <router-link to="/#explor-pharmacy" class="btn btn-primary position-relative">explore Pharmacy</router-link>
            </div>
          </div>
          <div class="col-2">
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-content">
    <div class="container-fluid content-section">
      <div class="col-md-8 offset-md-2 col-sm-12 text-center ">
        <h3 class="h2 faq-title text-center d-inline-block position-relative">What is pharmacy school?</h3>

        <p class="text-center main-paragraph">
          Pharmacy school refers to a program of study where students complete a graduate Doctor of Pharmacy degree program, Pharm.D. The Pharm.D. is required to be eligible to sit for the North American Pharmacist Licensure Examination (NAPLEX).
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center ">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          Should I go to pharmacy school?
        </h3>

        <p class="text-center main-paragraph">
          You should go to pharmacy school if you are looking for a career path in healthcare that provides you with a doctorate level degree and many varied opportunities including: clinical care, patient interaction, business, and more. It is a career path for students interested in chemistry, biology, and healthcare. If you enjoy making a great salary and having a diverse career path, pharmacy school may be for you.
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          How long is pharmacy school?
        </h3>

        <p class="text-center main-paragraph">
          The Doctor of Pharmacy (Pharm.D.) degree requires three (accelerated) to four years (most programs, traditional) of professional pharmacy course work. Typically, if a student decides to start as a freshman in college, it will take approximately 6 years. Either 6 years in the program total or 2 years as an undergraduate and 4 years in the professional program.
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          Do you need a Pharm.D. to become a pharmacist?
        </h3>

        <p class="text-center main-paragraph">
          Yes, in order to sit for the licensure exam, a student must be a graduate of a Pharm.D. program. A student is not eligible to sit for pharmacist licensure after completing a BS, MS, or Ph.D. in pharmaceutical science or a related major.
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          What can you do with a pharmacy degree?
        </h3>

        <p class="text-center main-paragraph">
          There are endless opportunities and career paths as a PharmD. You can work in a hospital pharmacy, community/retail pharmacy or even a mail order pharmacy. You can also not work in a pharmacy at all and build your career in industry, managed care, or as a business owner. These are just a few examples, to learn more, visit our partners Pharmacy Exploration Centers.
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          How many pharmacy schools are there in the US?
        </h3>

        <p class="text-center main-paragraph">
          Currently, there are approximately one hundred and forty schools of pharmacy in the United States.
        </p>
      </div>

      <div class="col-md-8 offset-md-2 col-sm-12 text-center">
        <h3 class="h3 faq-title text-center d-inline-block position-relative">
          What do you learn about in pharmacy school?
        </h3>

        <p class="text-center main-paragraph">
          Pharmacy school is broken into three phases: medicinal chemistry, clinical therapeutics, and experiential education. In the first two phases of pharmacy school, you will learn about the various body systems, drugs used to treat diseases, and the study of clinical therapeutics. Then in the last phase, you will experience different pharmacy practice settings during clinical rotations. These are called IPPEs (Introductory Pharmacy Practice Experiences) and APPEs (Advanced Pharmacy Practice Experiences). IPPEs and APPEs are essentially externships which encompass a variety of practice settings and clinical activities to provide students with a well-rounded, hands-on education and training in pharmacy.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>