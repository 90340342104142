<template>
  <div :class="title + ' explor-Pharmacy'">
    <main-navigation></main-navigation>
    <router-view/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import MainNavigation from "./components/MainNavigation";
import MainFooter from "./components/MainFooter";
import $ from 'jquery';

export default {
  name: 'App',
  data() {
    return {
      title: ''
    }
  },
  components: {
    MainFooter,
    MainNavigation,
  },
  mounted(){
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if ($('.navbar-collapse.show').length > 0) {
          $('#navbarSupportedContent').click();
        }

        document.title = to.meta.title || 'explor';
        this.title = document.title.replace(/\s/g , "-");

        if (typeof to.meta.metaTags  != 'undefined') {
          document.querySelector('meta[name="description"]').setAttribute("content",
              to.meta.metaTags[0].content);
        }
      },
    },
  }
}
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html,body {
  /*overflow-x: hidden;*/
}
body {
  /*background: #edece5;*/
  background: #fff;
  /*f9f9f8;*/
  color: #4f4f4f;
  overflow-x: hidden;
}
p {
  font-size: 18px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #4f4f4f;
  margin-top: 10px;
}
.w-33 {
  width: 33%;
}
.w-66 {
  width: 66%;
}
.hero {
  position: relative;
  top: -132px;
  margin-bottom: -132px;
  padding-top: 132px;
  min-height: 550px;
}
.video-hero-container {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#hero-video-bg {
  width: 100%;
  height: 100%;
  position: inherit;
  object-fit: cover;
}
.video-tinted {
  width: 100%;
  height: 100%;
  position: inherit;
  background: rgb(0 0 0 / 35%);
  z-index: 0;
}
.video-default-image {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-position: center;
  background-position-x: -1200px;
  background-image: url("assets/videos/Recruit_Video_Banner_Poster_v3.png");
}
.video-default-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0 0 0 / 45%);
}
.navbar {
  background: transparent;
  transition: .2s ease;
}
.icon-hidden-default {
  margin-right: 0;
}
.icon-hidden-default img {
  width: 0;
  transition: .2s ease;
}
.scrolled .icon-hidden-default img {
  width: 45px;
}
.navbar-light .navbar-toggler {
  background-color: rgba(255, 255, 255, 0.55);
}
.scrolled .icon-hidden-default {
  margin-right: 25px;
}
.display-image {
  border-radius: 20px;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0 11px 13px rgba(0,0,0,.25)
}
.btn {
  border-radius: 8px;
  padding: 15px 30px 15px 30px;
  transition: .2s;
  box-shadow: 0 8px 13px rgba(0,0,0,.25);
}
.btn-no-shadow {
  box-shadow: none;
}
.btn.btn-small {
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
}
.btn-primary {
  background: #2F516F;
  border-width: 0;
}
.btn-check:focus + .btn-primary, .btn-primary:focus,.btn-primary:hover,.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  background: #4e8dbf;
}
.btn-primary:focus {
  background: #2F516F;
}
.faq-title,
.paragraph-title,
.main-title, .subtitle {
  font-family: 'Poppins', 'Roboto', Helvetica, Arial, sans-serif;
}
.faq-title,
.paragraph-title,
.main-title {
  padding-bottom: 3px;
  font-weight: 900;
  font-size: 4rem;
  background-image: -webkit-linear-gradient(317deg, #111622, #6fb2e6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-filter: drop-shadow(0 10px 7px rgba(0,0,0,0.25));
  filter: drop-shadow(0 10px 7px rgba(0,0,0,0.25));
}
.main-title.light-main-title {
  background-image: -webkit-linear-gradient(317deg, #fff, #569bd1);
}
video[poster] {
  object-fit: fill;
  max-width: 100%;
}
.light-title {
  color: #edece5;
  background-image: none;
  -webkit-text-fill-color: inherit;
}
.paragraph-title {
  bottom: -35px;
  margin-top: -35px;
  width: 100%;
}
.faq-title {
  font-size: 2.5rem;
  margin-top: 0;
  bottom: -35px;
  width: 80%
}
.body-content .content-section {
  margin-top: 60px;
}
.body-content .content-section:first-child {
  margin-top: 0;
}
.main-paragraph {
  background: #fff;
  border-radius: 12px;
  width: 90%;
  margin: 0 auto;
  padding: 35px 30px 25px;
}
.overlayed-image {
  height: 350px;
  width: 450px;
  right: 0
}
.parallax {
  /* The image used */
  background: rgb(17,22,34);
  background: linear-gradient(139deg, rgba(17,22,34,1) 38%, rgba(52,90,123,1) 100%);
  /* Set a specific height */
  min-height: 500px;
  margin-top: 25px;
  position: relative;
}
.parallax p,
.parallax h4 {
  color: #fff;
}
.parallax::before {
  z-index: 0;
  content: "";
  background-image: url("assets/images/topography-background-transparent.png");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-attachment: fixed;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: .1;
}
.parallax div {
  z-index: 10;
}
.navbar-social-list li {
  margin-right: 15px;
}
#explor-news-title,
#about-us-title {
  padding-top: 20px;
}
.header-paragraph {
  font-size: 22px;
}
@media (max-width: 991.98px) {
  .header-paragraph {
    font-size: 18px;
  }
  #explor-news-title {
    padding-top: 80px;
  }
  #about-us-title {
    padding-top: 80px;
  }
  .hero {
    top: -156px;
    margin-bottom: -156px;
  }
  .fa-2x {
    font-size: 1.1em;
  }
  .scrolled .icon-hidden-default {
    margin-right: 35px;
  }
  .navbar-nav {
    text-align: center;
  }
  .navbar-side-button {
    text-align: center;
  }
  .navbar-social-list {
    flex-direction: row;
  }
  .navbar-social-list li {
    display: inline-block;
    font-size: 2rem;
    margin-right: 35px;
  }
  .title-overlay {
    margin-top: 210px;
    font-size: 2.9rem
  }
  .title-overlay-small-margin {
    margin-top: 20px;
  }
  .video-title-overlay {
    margin-top:0;
  }
  .overlayed-image {
    width: 90%;
    left: -10%;
  }
  .header-button {
    text-align: center;
  }
  .parallax {
    background-attachment: scroll;
  }
}
@media (max-width: 1199.98px) {
  .hero {
    top: -156px;
    margin-bottom: -156px;
  }
  .header-button {
    margin-bottom: 10px;
  }
}
</style>
