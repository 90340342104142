<template>
  <div class="body-content">
    <section class="hero position-relative">
      <div class="video-hero-container">
        <div class="video-tinted"></div>
        <video autoplay muted loop id="hero-video-bg" class="d-sm-block" style="z-index: -1;" poster="../assets/videos/Recruit_Video_Banner_Poster.png" v-if="!isMobile()">
          <source src="../assets/videos/Recruit_Video_Banner_Sm.mp4" type="video/mp4">
        </video>
        <div class="video-default-image d-md-none" v-if="!isMobile()"></div>

        <div class="video-default-image" v-if="isMobile()"></div>
      </div>

      <div class="hero-body">
        <div class="row">
          <div class="col-8 text-left offset-2 position-relative">

            <h1 class="h1 main-title video-title-overlay light-main-title title-overlay d-inline-block position-relative" style="padding-top:20px;">About explor</h1>
            <p class="position-relative header-paragraph" style="max-width: 600px; color: #fff">explor.pharmacy educates prospective pharmacy students on the exciting and diverse career paths pharmacy has to offer by introducing them to the CAREER EXPLORATION CENTERS of leading colleges of pharmacy. explor.pharmacy is managed by CORE Higher Education Group.</p>
            <div class="header-button">
              <router-link to="/#explor-pharmacy" class="btn btn-primary position-relative">explore Pharmacy</router-link>
            </div>
          </div>
          <div class="col-2">
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="body-content">
    <div class="container-fluid content-section" style="margin-top: 40px;">
      <div class="col-8-md offset-2-md">
        <h2 class="h2 paragraph-title text-center d-inline-block position-relative">Our Mission</h2>

        <p class="text-center main-paragraph" style="max-width: 800px; padding-bottom: 0;">
          Our mission is to ensure any student interested in a potential career in healthcare discovers pharmacy as an option and has accessible resources to educate themselves on the various career paths available.
        </p>
      </div>
    </div>

    <div class="parallax content-section" style="min-height: auto; padding-bottom: 50px;">
      <div class="container">
        <h1 class="h1 main-title light-title title-overlay text-center d-inline-block position-relative" style="padding-top:20px; width: 100%">Our Values</h1>
        <div class="row col-10 offset-1" style="margin-top: 25px;">
          <div class="col-md-4 ">
            <h4 class="h4">We encourage</h4>
            <p>
              individuals of all backgrounds to explore pharmacy as a career option.
            </p>
          </div>
          <div class="col-md-4">
            <h4 class="h4">We strive to be as</h4>
            <p>
              comprehensive as possible in presenting the career paths and opportunities pharmacy has to offer.
            </p>
          </div>
          <div class="col-md-4">
            <h4 class="h4">We hope to inspire the</h4>
            <p>
              next generation of pharmacists on the impact their role in healthcare will have.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
}
</script>